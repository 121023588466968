import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Collapse, 
  Typography, 
  styled,
  useTheme,
  CardContent,
  Card,
  Backdrop,
  Button,
  Box
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { Link } from 'react-router-dom';
import { iconsConst } from '../../utils/const';
import SearchBar from '../0_Common/Search';
import { useWebSocketSelector } from '../../hooks/useWebsocket';
import _ from 'lodash';

const StyledDrawer = styled(Drawer)(({ theme, breakpoint }) => ({
  flexShrink: 0,
  borderRight: 'none !important',
  margin: '8px 12px',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width:'70vw',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderRight: 'none !important',
    padding: '12px',

    ...(breakpoint !== "always" && {
      position: 'fixed',
      height: '100%',
      top: 'unset',
     // Add this to ensure it doesn't overflow
     width:'20vw !important',
    }),

  },
}));

const ScrollableContainer = styled('div')(({ theme }) => ({
  
  overflowY: 'auto',
  borderTop: `solid 1px ${theme.palette.primary.main}`,
  borderBottom: `solid 1px ${theme.palette.primary.main}`,
marginBottom: '10px',

  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: '4px',
  },
}));

const SearchBarContainer = styled('div')(({ theme }) => ({
  padding: '8px 4px 8px 4px',
  marginBottom: '10px',
  backgroundColor: theme.palette.buttonHoverColor,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '10px 30px',
  color: theme.palette.primary.text,
  backgroundColor: theme.palette.primary.main,

  '&:hover, &.Mui-selected': {
    backgroundColor: ` ${theme.palette.buttonHoverColor}!important`,
    color: '#000000 !important',
    fontWeight: 500,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '48px', 
  '& > svg': {
    fontSize: '23px', // Increase icon size
  },
}));

const SideBarLeft = ({ toggled, onBackdropClick, breakpoint }) => {
  const { marketIds } = useWebSocketSelector(state => state.websocket);
  const theme = useTheme();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [openMenus, setOpenMenus] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const groupedMenuArray = useMemo(() => {
    return _.chain(marketIds)
      .filter(market => {
        const now = new Date();
        const fourHoursAgo = new Date(now.getTime() - 4 * 60 * 60 * 1000);
        const matchDate = new Date(market.date);
        return matchDate >= fourHoursAgo;
      })
      .groupBy('sport_name')
      .map((data, key) => ({
        gameType: key,
        totalMatches: _.uniqBy(data, 'match_id').length,
        seriesNames: _.chain(data)
          .uniqBy('match_id')
          .groupBy('series_name')
          .map((seriesData, seriesKey) => ({
            seriesName: seriesKey,
            allMatches: seriesData
          }))
          .value()
      }))
      .value();
  }, [marketIds]);

  const searchFunction = useCallback((value) => {
    if (!marketIds) return [];
    const matches = marketIds && Object.values(marketIds).map(data => ({
      label: data?.match_name,
      url: `/sports/${data?.sport_name.toLowerCase()}/${data?.market_id}/${data?.match_id}`
    }));
    return Array.isArray(matches) && matches?.filter(i => i?.label?.toLowerCase()?.includes(value?.toLowerCase()));
  }, [marketIds]);

  const handleClick = (key) => {
    setOpenMenus(prevState => ({ ...prevState, [key]: !prevState[key] }));
  };

  const renderMenu = (menuArray) => {
    return menuArray.map((el) => (
      <React.Fragment key={el.gameType}>
        <StyledListItem button onClick={() => handleClick(el.gameType)}>
          <StyledListItemIcon>
            {iconsConst[el.gameType.toLowerCase().concat('White')]}
          </StyledListItemIcon>
          <ListItemText 
          
            secondary={ <Typography variant="body2" style={{fontSize: '16px'}}>
            {el.gameType} 
          </Typography>}
            primary={
             <Typography variant="body2" style={{fontSize: '10px',color:'#9999'}}>{el.totalMatches} Matches</Typography>
            } 
          />
          {openMenus[el.gameType] ? <ExpandLess /> : <ExpandMore />}
        </StyledListItem>
        {/* <Collapse in={openMenus[el.gameType]} timeout="auto" unmountOnExit> */}
          <List component="div" disablePadding>
            {el.seriesNames.map(elSeries => (
              <React.Fragment key={elSeries.seriesName}>
                <StyledListItem button onClick={() => handleClick(elSeries.seriesName)} sx={{ pl: 4 }}>
                  <ListItemText primary={<Typography variant="body2" style={{fontSize: '14px'}}>{elSeries.seriesName}</Typography>} />
                  {openMenus[elSeries.seriesName] ? <ExpandLess /> : <ExpandMore />}
                </StyledListItem>
                <Collapse in={openMenus[elSeries.seriesName]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {elSeries.allMatches.map(elMatch => (
                      <StyledListItem 
                        button 
                        key={elMatch.match_id} 
                        component={Link} 
                        to={`/sports/${elMatch.sport_name.toLowerCase()}/${elMatch.market_id}/${elMatch.match_id}`}
                        sx={{ pl: 6 }}
                      >
                        <ListItemText primary={<Typography variant="body2" style={{fontSize: '12px'}}>{elMatch.match_name}</Typography>} />
                      </StyledListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        {/* </Collapse> */}
      </React.Fragment>
    ));
  };

  const handleBackdropClick = (event) => {
    // Stop propagation to prevent the event from reaching the drawer
    event.stopPropagation();
    onBackdropClick();
  };

  return (
    <>
      <StyledDrawer
        variant={breakpoint === "always" ? "temporary" : "permanent"}
        anchor="left"
        open={breakpoint === "always" ? toggled : true}
        onClose={onBackdropClick}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        breakpoint={breakpoint}
      >
        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
          <Logo color='black' />
          <Typography variant="body2" style={{ color: 'bla', marginTop: '5px' }}>
            {currentDateTime.toLocaleString()}
          </Typography>
        </div> */}

        <SearchBarContainer>
          <SearchBar searchFunction={searchFunction} />
        </SearchBarContainer>

        <ScrollableContainer>
          <List sx={{padding: '4px 0px 4px 0px'}}>
            
            {renderMenu(groupedMenuArray)}
          </List>
        </ScrollableContainer>

        <Card sx={{ 
          mb: 2, 
          position: 'relative',
          color: 'primary.contrastText',
          boxShadow: 3,
          overflow: 'hidden',
        }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: 'url("/cardBackground.jpg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)', // Darkening overlay
              },
            }}
          />
          <CardContent sx={{ position: 'relative', zIndex: 1 }}>
            <Box display="flex" alignItems="center" mb={1}>
              <SportsSoccerIcon sx={{ mr: 1 }} />
              <Typography variant="h6" component="div">
                LIVE BETTING
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Experience the thrill of live sports betting. Place your bets in real-time as the action unfolds!
            </Typography>
            <Button 
              variant="contained" 
              color="secondary" 
              fullWidth
              sx={{ 
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.light,
                }
              }}
            >
              PLAY NOW
            </Button>
          </CardContent>
        </Card>

        
      </StyledDrawer>
      {breakpoint === "always" && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={toggled}
          onClick={onBackdropClick}
        />
      )}
    </>
  );
}

export default React.memo(SideBarLeft);
