import * as React from 'react'
import {
  Button,
  Divider,
  Grid,
  Typography,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  useWebSocketContext,
  useWebSocketSelector,
} from '../../hooks/useWebsocket'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { useDispatch } from 'react-redux'
import { updateBetSlip } from '../../store/appSlice'
import { keyframes } from '@mui/material/styles'
import LayBackButtonComponent from './LayBackButtonComponent'

const CardRoot = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  marginBottom: theme.spacing(2),
}))

const TitleContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  letterSpacing: '0.5px',
}))

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  marginTop: theme.spacing(0.5),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  textAlign: 'center',
  color: theme.palette.text.primary,
}))

const StyledButton = styled(Button)(({ theme, variant }) => ({
  fontSize: theme.typography.pxToRem(14),
  alignItems: 'center',
  color: theme.palette.text.primary,
  textAlign: 'center',
  padding: '2px 2px 2px !important',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 0,
  width: '95%',
  background:
    variant === 'back'
      ? theme.palette.backButtonColor
      : theme.palette.layButtonColor,
  '&:hover': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
  '&:active': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
  '&:focus': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
}))

const TeamTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(1.25),
  fontWeight: 700,
}))

const PLTypography = styled(Typography)(({ theme, isPositive }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  color: isPositive ? 'green' : 'red',
  backgroundColor: isPositive ? '#e6ffe6' : '#ffe6e6',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  marginLeft: 'auto',
}))

const pulse = keyframes`
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
`

const AnimatedOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  zIndex: 1,
  animation: `${pulse} 1.5s ease-in-out infinite`,
}))

const FancyCard = React.memo(() => {
  const location = useLocation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { fancyValues, updatedMarketDetails } = useWebSocketContext()
  const { marketIds } = useWebSocketSelector((state) => state.websocket)
  const currentGameIdFromLocation = location.pathname.split('/')[3]
  const matchOddsData = marketIds[currentGameIdFromLocation]

  const [showSkeleton, setShowSkeleton] = React.useState(true)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  const toggleBetModify = (index, side, odds) => {
    dispatch(
      updateBetSlip({
        betType: 'fancy',
        side,
        odds,
        market: fancyValues[index],
        index,
      }),
    )
  }

  if (fancyValues.length === 0) {
    if (!showSkeleton) return null

    return (
      <CardRoot>
        <TitleContainer>
          <TitleTypography>Fancy</TitleTypography>
          <SubtitleTypography variant="subtitle2">
            <Skeleton width={150} />
          </SubtitleTypography>
        </TitleContainer>
        <Grid container spacing={1} sx={{ padding: '0 2px 2px' }}>
          {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
              <Grid item xs={7}>
                <Skeleton height={40} />
              </Grid>
              <Grid item xs={2.5}>
                <Skeleton height={40} />
              </Grid>
              <Grid item xs={2.5}>
                <Skeleton height={40} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardRoot>
    )
  }

  return (
    <CardRoot>
      <TitleContainer>
        <TitleTypography>Fancy</TitleTypography>
        <SubtitleTypography variant="subtitle2">
          Min: {matchOddsData?.min_stack} &nbsp; Max:{' '}
          {matchOddsData?.max_stack}
        </SubtitleTypography>
      </TitleContainer>
      <Grid container spacing={1} sx={{ padding: '0 2px 2px' }}>
        <Grid item xs={7}>
          {/* Empty grid item for alignment */}
        </Grid>
        {['Lay', 'Back'].map((side) => (
          <Grid
            item
            xs={2.5}
            key={side}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0'
            }}
          >
            <StyledTypography>{side}</StyledTypography>
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Grid container>
        {fancyValues && fancyValues.map((fancy, index) => {
          const updatedRunnerJSON = updatedMarketDetails?.markets?.find(i => i.market_id === fancy.matchId+'_'+fancy.selectionId);
          const pl = Number(updatedRunnerJSON?.pl) || 0;
          return (
            <React.Fragment key={fancy.fancyId}>
              <Grid container xs={12} lg={12} md={12} sm={12}
                sx={{ backgroundColor: index % 2 === 0 ? '#f2f1ed' : theme.palette.grey[300], }}
              >
                <Grid
                  item
                  xs={7}
                  style={{ display: 'flex', alignItems: 'center' }}
                  sx={{ padding: '0 4px' }}
                >
                  <TeamTypography>{fancy.RunnerName}</TeamTypography>
                  {pl ? (
                    <PLTypography isPositive={pl >= 0}>
                      {pl >= 0 ? '+' : '-'}
                      {Math.abs(pl).toFixed(2)}
                    </PLTypography>
                  ) : null}
                </Grid >
                <Grid container xs={5} lg={5} md={5} sm={5} item sx={{position: 'relative'}}>
                <Grid item xs={6} lg={6} md={6} sm={6}>
                  <LayBackButtonComponent
                    variant="lay"
                    onClick={() => toggleBetModify(index, 'lay', fancy.LayPrice1)}
                    disabled={fancy.GameStatus === 'SUSPENDED'}
                    price={fancy.LayPrice1}
                    size={fancy.LaySize1}
                  />
                </Grid>
                <Grid item xs={6} lg={6} md={6} sm={6}>
                  <LayBackButtonComponent
                    variant="back"
                    onClick={() => toggleBetModify(index, 'back', fancy.BackPrice1)}
                    disabled={(fancy.GameStatus === 'SUSPENDED' || fancy.GameStatus === 'BALL RUNNING')}
                    price={fancy.BackPrice1}
                    size={fancy.BackSize1}
                  />
                  {fancy.GameStatus === 'SUSPENDED' || fancy.GameStatus === 'BALL RUNNING' ? (
                    <AnimatedOverlay>
                      <Typography fontSize={'12px'} fontWeight={800} sx={{textTransform:'capitalize',color:'red'}}>
                        {fancy.GameStatus}
                      </Typography>
                    </AnimatedOverlay>
                  ) : null}
                </Grid>
                </Grid>
                
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </CardRoot>
  )
})

export default FancyCard
