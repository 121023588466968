import React, { useEffect, useState } from 'react'
import { styled, ThemeProvider, createTheme } from '@mui/material/styles'
import {
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import GoogleIcon from '@mui/icons-material/Google'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import useApiFetch from '../../hooks/useApiFetch'
import { loginUrl, UserSettingUrl } from '../../constants'
import { demoUserData } from '../../utils/const'
import { BetLimitRuleModal } from './BetLimitRule'
import Logo from './Logo'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import { useNavigate } from 'react-router-dom'

const theme = createTheme({
  palette: {
    primary: {
      main: '#232234',
    },
    background: {
      default: '#242323',
    },
    buttonPrimaryColor: '#9E172F',
  },
})

const LoginContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  maxWidth: 400,
  margin: 'auto',
  marginTop: '10%',
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.buttonPrimaryColor,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}))

const SocialButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  },
  '& .MuiFilledInput-input': {
    color: theme.palette.common.white,
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  '& .MuiInputAdornment-root': {
    marginTop: '0 !important',
  },
  '& .MuiInputAdornment-positionStart': {
    marginRight: theme.spacing(1),
  },
  '& .MuiInputAdornment-positionEnd': {
    marginLeft: theme.spacing(1),
  },
}))

function Login({ onClose }) {
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  })
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [loginType, setLoginType] = useState('User ID')
  const [anchorEl, setAnchorEl] = useState(null)
  const [showBetLimitModal, setShowBetLimitModal] = useState(false)
  const [notUserError, setNotUserError] = useState(false)
  const { data: loginData, fetchData: fetchLoginData, loading, error } = useApiFetch(loginUrl, {
    method: 'POST',
    body: JSON.stringify({
      user_name: loginType === 'User ID' ? formData.identifier : '',
      password: formData.password,
      mobile: loginType === 'Mobile Number' ? formData.identifier.replace(/\D/g, '') : '',
      login_type: loginType === 'User ID' ? 'user_name' : 'mobile',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const { data: userSettingsData, fetchData: fetchUserSettings } = useApiFetch(UserSettingUrl, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "accept": "application/json, text/plain, */*",
      "authorization": loginData?.data?.token,
      "content-type": "application/json"
    },
    body: JSON.stringify({
      user_id: loginData?.data?.id,
    }),
  })

  useEffect(() => {
  
    if (loginData) {
      if(loginData.data.user_type_id===10){

        sessionStorage.setItem('userDetails', JSON.stringify(loginData.data))
        fetchUserSettings()
      }else{
        setNotUserError(true)
      }
    }
  }, [loginData])

  useEffect(() => {
    if (userSettingsData) {
      sessionStorage.setItem('userSettings', JSON.stringify(userSettingsData))
      setShowBetLimitModal(true)
      navigate('#', { state: { openLoginModal: false } })
    }
  }, [userSettingsData])

  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters
    const phoneNumber = value.replace(/\D/g, '')

    // Format the phone number as (XXX) XXX-XXXX
    if (phoneNumber.length <= 3) {
      return phoneNumber
    } else if (phoneNumber.length <= 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6,
      )}-${phoneNumber.slice(6, 10)}`
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'identifier' && loginType === 'Mobile Number') {
      setFormData({
        ...formData,
        [name]: formatPhoneNumber(value),
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setNotUserError(false)
    await fetchLoginData()
  }

  const handleDemoIdClick = () => {
    sessionStorage.setItem('userDetails', JSON.stringify(demoUserData))
    window.location.reload()
  }

  const handleLoginTypeClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleBetLimitModalClose = () => {
    setShowBetLimitModal(false)
    window.location.reload()
  }

  const handleLoginTypeClose = (type) => {
    if (type) {
      setLoginType(type)
      setFormData({
        identifier: '',
        password: '',
      })
    }
    setAnchorEl(null)
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <ThemeProvider theme={theme}>
      <LoginContainer>
        <Box
        
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Logo />
          <Typography variant="h5" color="common.white">
            Log in
          </Typography>
          {onClose && <IconButton onClick={onClose} sx={{ color: 'common.white' }}>
            <CloseIcon />
          </IconButton>}
        </Box>
        <StyledForm onSubmit={handleSubmit}>
          <StyledTextField
            fullWidth
            variant="filled"
            name="identifier"
            
            placeholder={loginType === 'User ID' ? 'ID' : 'Mobile Number'}
            value={formData.identifier}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {loginType === 'User ID' ? (
                    <PersonIcon sx={{ color: 'white' }} />
                  ) : (
                    <PhoneIcon sx={{ color: 'white' }} />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleLoginTypeClick} sx={{ color: 'white' }}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleLoginTypeClose()}
          >
            <MenuItem 
              onClick={() => handleLoginTypeClose('User ID')}
              autoFocus
            >
              User ID
            </MenuItem>
            <MenuItem onClick={() => handleLoginTypeClose('Mobile Number')}>
              Mobile Number
            </MenuItem>
          </Menu>
          <StyledTextField
            fullWidth
            variant="filled"
            
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                   
                    sx={{ color: 'white' }}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Typography variant="body2" color="red" align="center">
              {error.message}
            </Typography>
          )}
          {notUserError && (
            <Typography variant="body2" color="red" align="center">
              Not a Valid User
            </Typography>
          )}
          {/* <Typography
            variant="body2"
            color="common.white"
            align="right"
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            Forgot password?
          </Typography> */}
          <StyledButton fullWidth type="submit" variant="contained">
            {loading ? (
              <>
                <CircularProgress size={24} sx={{ marginRight: '10px' }} />{' '}
                Logining...
              </>
            ) : (
              'Login'
            )}
          </StyledButton>
          <StyledButton
            fullWidth
            variant="contained"
            onClick={handleDemoIdClick}
          >
            Login with Demo ID
          </StyledButton>
        </StyledForm>
        <BetLimitRuleModal
          open={showBetLimitModal}
          onClose={handleBetLimitModalClose}
        />
      </LoginContainer>
    </ThemeProvider>
  )
}

export default Login
