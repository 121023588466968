import React, { useRef, useState, useEffect } from 'react'
import {
    styled,
    Typography,
    keyframes,
    Button,
} from '@mui/material'

//component get props value and display it
//we store it in a ref old value
// compare the new value with it if same then do nothing
// if different then update the value and store it in ref and run a flickering animation  


const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  textAlign: 'center',
  color: theme.palette.text.primary,
}))

const StyledButton = styled(Button)(({ theme, isFlickering, variant }) => ({
  fontSize: theme.typography.pxToRem(14),
  alignItems: 'center',
  color: theme.palette.text.primary,
  textAlign: 'center',
  padding: '2px 2px 2px !important',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 0,
  width: '95%',
  background:
    variant === 'back'
      ? theme.palette.backButtonColor
      : theme.palette.layButtonColor,
  '&:hover': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
  '&:active': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
  '&:focus': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
  animation: isFlickering ? `${flickerAnimation} 0.5s ease-in-out` : 'none',
}))

const flickerAnimation = keyframes`
  0% { background-color: transparent; }
  50% { background-color: rgba(255, 255, 0, 0.3); }
  100% { background-color: transparent; }
`

const LayBackButtonComponent = ({price, size, variant='lay', ...restProps}) => {
  const oldPrice = useRef(price)
  const oldSize = useRef(size)
  const [isFlickering, setIsFlickering] = useState(false)

  useEffect(() => {
    if (price !== oldPrice.current || size !== oldSize.current) {
      setIsFlickering(true)
      oldPrice.current = price
      oldSize.current = size
      
      // Reset the flickering state after animation completes
      const timer = setTimeout(() => {
        setIsFlickering(false)
      }, 500) // 500ms matches the animation duration

      return () => clearTimeout(timer)
    }
  }, [price, size])

  return (
    <StyledButton
      variant={variant}
      {...restProps}
      isFlickering={isFlickering}
      sx={{
        marginBottom: '3.1px',
        padding: '8px !important',
      }}
    >
      <Typography fontSize={'12px'} fontWeight={600}>
        {price}
      </Typography>
      <Typography fontSize={'9px'}>
        {size}
      </Typography>
    </StyledButton>
  )
}

export default LayBackButtonComponent
