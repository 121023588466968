import * as React from 'react'
import {
  Button,
  Divider,
  Grid,
  Typography,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  useWebSocketContext,
  useWebSocketSelector,
} from '../../hooks/useWebsocket'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { useDispatch } from 'react-redux'
import { updateBetSlip } from '../../store/appSlice'
import LayBackButtonComponent from './LayBackButtonComponent'

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  textAlign: 'center',
  color: theme.palette.text.primary,
}))

const StyledButton = styled(Button)(({ theme, variant }) => ({
  fontSize: theme.typography.pxToRem(14),
  alignItems: 'center',
  color: theme.palette.text.primary,
  textAlign: 'center',
  padding: '2px 2px 2px !important',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 0,
  width: '95%',
  background:
    variant === 'back'
      ? theme.palette.backButtonColor
      : theme.palette.layButtonColor,
  '&:hover': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
  '&:active': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
  '&:focus': {
    background:
      variant === 'back'
        ? theme.palette.backButtonColor
        : theme.palette.layButtonColor,
  },
}))

const TeamTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(1.25),
  fontWeight: 700,
}))

const CardRoot = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,

  boxShadow: theme.shadows[0],
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  marginBottom: theme.spacing(2),
}))

const PLTypography = styled(Typography)(({ theme, isPositive }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  color: isPositive ? 'green' : 'red',
  backgroundColor: isPositive ? '#e6ffe6' : '#ffe6e6',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  marginLeft: 'auto',
}))

const TitleContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
  // borderRadius: `6px 6px 0 0`,

}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  letterSpacing: '0.5px',
}))

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  marginTop: theme.spacing(0.5),
}))


const MatchOddsCard = () => {
  const location = useLocation()
  const [openMatchOddsBetModify, setOpenMatchOddsBetModify] =
    React.useState(null)
  const [openBookmakerBetModify, setOpenBookmakerBetModify] =
    React.useState(null)
  const { status, marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )
  const theme = useTheme()
  const dispatch = useDispatch()

  const { updatedMarketDetails, marketsIdsArray, bookmakerOdds } =
    useWebSocketContext()
  const currentGameIdFromLocation = location.pathname.split('/')[3]
  const matchOddsData = marketsIdsArray.find(
    (i) => i.marketId === currentGameIdFromLocation,
  )
  const currentBookmakerOdds = Object.values(bookmakerOdds)[0]

  if (!matchOddsData || status !== 'CONNECTED') {
    return (
      <CardRoot>
     <TitleContainer>
          <TitleTypography>Match Odds</TitleTypography>
          <SubtitleTypography variant="subtitle2">
            <Skeleton width={150} />
          </SubtitleTypography>
        </TitleContainer>
        <Grid container spacing={1} sx={{ padding: '8px' }}>
          {[...Array(3)].map((_, index) => (
            <React.Fragment key={index}>
              <Grid item xs={7}>
                <Skeleton variant="text" width="80%" />
              </Grid>
              <Grid item xs={2.5}>
                <Skeleton variant="rectangular" height={40} />
              </Grid>
              <Grid item xs={2.5}>
                <Skeleton variant="rectangular" height={40} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardRoot>
    )
  }

  const toggleMatchOddsBetModify = (index, side, odds) => {

    dispatch(
      updateBetSlip({
        betType:'market',
        index,
        side,
        odds,
        market: { ...matchOddsData, ...marketIds[currentGameIdFromLocation] },
      }),
    )
  }

  const toggleBookmakerBetModify = (index, side, odds) => {
    dispatch(
      updateBetSlip({
        betType:'market',
        index,
        side,
        odds,
        market: { ...matchOddsData, ...marketIds[currentGameIdFromLocation] },
      }),
    )
  }

  const closeMatchOddsBetModify = () => {
    setOpenMatchOddsBetModify(null)
  }

  const closeBookmakerBetModify = () => {
    setOpenBookmakerBetModify(null)
  }

  const updatedRunnerJSON = updatedMarketDetails?.markets?.find(
    (i) => i?.market_id === matchOddsData?.market_id,
  )?.runner_json

  const renderOddsCard = (
    data,
    title,
    minStack,
    maxStack,
    updatedRunnerJSON,
    betModifyState,
    toggleBetModify,
    closeBetModify,
  ) => (
    <>
      <TitleContainer>
        <TitleTypography >{title}</TitleTypography>
        <SubtitleTypography variant="subtitle2">
          Min: {minStack} &nbsp; Max: {maxStack}
        </SubtitleTypography>
      </TitleContainer>
      <CardRoot>
        <Grid container spacing={1} sx={{ padding: '0 2px 2px' }}>
          <Grid item xs={7}>
            {/* Empty grid item for alignment */}
          </Grid>
          <Grid
            item
            xs={2.5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0'
            }}
          >
            <StyledTypography>Back</StyledTypography>
          </Grid>
          <Grid
            item
            xs={2.5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0'
            }}
          >
            <StyledTypography>Lay</StyledTypography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container>
          {data &&
            data['runners']?.map((i, index) => (
              <React.Fragment key={i.id}>
                <Grid container xs={12} lg={12} md={12} sm={12}
                sx={{ backgroundColor: index % 2 === 0 ? '#f2f1ed' : theme.palette.grey[300], }}
                >
                  <Grid
                    item
                    xs={7}
                    style={{ display: 'flex', alignItems: 'center' }}
                    sx={{ padding: '0 4px' }}
                  >
                    <TeamTypography>{i.name}</TeamTypography>
                    {updatedRunnerJSON &&
                      updatedRunnerJSON[index] &&
                      updatedRunnerJSON[index].pl !== undefined && (
                        <PLTypography
                          isPositive={updatedRunnerJSON[index].pl >= 0}
                        >
                          {updatedRunnerJSON[index].pl >= 0 ? '+' : '-'}
                          {Math.abs(updatedRunnerJSON[index].pl).toFixed(2)}
                        </PLTypography>
                      )}
                  </Grid>
                  <Grid item xs={2.5} sx={{ marginBottom: '3.1px' }}>
                    <LayBackButtonComponent
                      price={i.ex['availableToBack'][0].price}
                      size={i.ex['availableToBack'][0].size}
                      variant="back"
                      onClick={() =>
                        toggleBetModify(
                          index,
                          'back',
                          i.ex['availableToBack'][0].price,
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <LayBackButtonComponent
                      price={i.ex['availableToLay'][0].price}
                      size={i.ex['availableToLay'][0].size}
                      variant="lay"
                      onClick={() =>
                        toggleBetModify(
                          index,
                          'lay',
                          i.ex['availableToLay'][0].price,
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Collapse in={betModifyState?.index === index}>
                {betModifyState?.index === index && (
                  <BetModifyComponent
                    marketDetails={betModifyState}
                    onCancel={closeBetModify}
                  />
                )}
              </Collapse> */}
                    <Divider />
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      </CardRoot>
    </>
  )

  return (
    <>
      {renderOddsCard(
        matchOddsData,
        'Match Odds',
        marketIds?.[currentGameIdFromLocation]?.min_stack,
        marketIds?.[currentGameIdFromLocation]?.max_stack,
        updatedRunnerJSON,
        openMatchOddsBetModify,
        toggleMatchOddsBetModify,
        closeMatchOddsBetModify,
      )}
      {currentBookmakerOdds &&
        currentBookmakerOdds?.markets === matchOddsData?.market_id &&
        renderOddsCard(
          currentBookmakerOdds,
          'Bookmaker',
          currentBookmakerOdds.min_stack,
          currentBookmakerOdds.max_stack,
          updatedRunnerJSON,
          openBookmakerBetModify,
          toggleBookmakerBetModify,
          closeBookmakerBetModify,
        )}
    </>
  )
}

export default MatchOddsCard
